<template>
  <div class="PageBox">
    <van-nav-bar
            fixed
            :border="false"
            :title="$t('withdraw2[0]')"
            left-arrow @click-right="onClickRight"
            @click-left="$router.go(-1)"
    ><van-icon name="./static/icon/task01.png" slot="right" />
    </van-nav-bar>
      <div class="box">
        <div>
          <van-field
                  v-model="BankInfo.name" readonly
                  :label="$t('withdraw3.fields[0]')"
                  :placeholder="$t('withdraw3.placeholder[0]')"
          />
          <van-field
                  v-model="BankInfo.phone" readonly
                  :label="$t('withdraw3.fields[1]')"
                  :placeholder="$t('withdraw3.placeholder[1]')"
          />
          <van-field
                  v-model="BankInfo.iban" readonly
                  :label="$t('withdraw3.fields[2]')"
                  :placeholder="$t('withdraw3.placeholder[2]')"
          />
          <van-field
                  v-model="BankInfo.bank_name" readonly
                  :label="$t('withdraw3.fields[3]')"
                  :placeholder="$t('withdraw3.placeholder[3]')"
          />
          <van-field
                  v-model="BankInfo.upi" readonly
                  :label="$t('withdraw3.fields[4]')"
                  :placeholder="$t('withdraw3.placeholder[4]')"
          />
          <van-field v-model="BankInfo.money"
                     :label="$t('wallet.label[1]')"
                     :placeholder="$t('wallet.placeholder[1]')"
          />
        </div>
      </div>
        <div style="text-align: center;margin-top: 20px">
          <p class="btn" @click="submitDraw">{{$t('recharge.default[4]')}}</p>
        </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import axios from "axios";
import { Toast } from "vant";
import config from "@/config";

export default {
  name: "mixRecharge",
  components: {},
  props: ["walletType"],
  data() {
    return {
      showPicker: false,
      columns:[],
      BankInfo:{},
    };
  },
  computed: {

  },
  watch: {},
  created() {

    this.$Model.GetBankCardList(data=>{
      if (data.code==1&&data['data'].length>0){
            this.BankInfo = data['data'][0];
          }
        }
    );
  },
  mounted() {
    // this.getQRUrl();
  },
  activated() {},
  destroyed() {
  },
  methods: {
    onClickRight(){
      this.$router.push("/user/withdrawlist");
    },
    submitDraw() {
      let postData = this.BankInfo;
      this.$Model.Draw(postData, (data) => {
        // this.isSubmit = false;
        let msg = this.$t('taskOrder2[3]');
        switch (data.code) {
          case 1:
            msg = this.$t('common3[0]');
            break;
          case -1:
            msg = this.$t('withdraw2[6]');
            break;
          default:
            msg = data.code_dec;
            break;
        }
        this.$Dialog.Toast(msg);
        if (data.code==1){
          this.$router.push("/user");
        }
      });
    },
  },
};
</script>

<style scoped>
/* .van-nav-bar--fixed,.Site .van-nav-bar{
    background-color: #4e51bf;
} */
.PageBox {
  color: #d7d7d7;
  background-color: #13171A;
}

.van-nav-bar {
  background-color: #191C23;
}
.van-nav-bar>>>.van-nav-bar__title{
  color:#fff;
  font-weight: bold;
}
.van-nav-bar>>>.van-nav-bar__arrow {
  color:#fff;
}

.PageBox .van-cell{
  background-color: #191C23;
}

.PageBox .van-cell>>>.van-cell__title{
  font-size: 16px;
  font-weight: bold;
  color: #d7d7d7;
}
.PageBox>>>.van-field__control {
  color: #fff;
}
.PageBox .box {
  width: 95%;
  padding: 10px 0px 10px 10px;
  font-size: 19px;
  margin-top: 15px;
  border-radius: 10px;
  margin-left: 10px;
  background-color: #191C23;
  color: #555;
}


p{
  width: 80px;
}


.btn {
  width: 85%;
  padding: 10px 50px;
  border-radius: 15px;
  background-color: #0076FA;
  color: #fff;
  font-size: 18px;
  text-align: center;
  margin: 15px 30px 30px;
}
</style>
